import React, { ReactElement } from 'react';

interface Props {
  formkitToggle: string;
  children: string | ReactElement;
}

const ConvertkitButton: React.FC<Props> = ({ formkitToggle, children }) => {
  return (
    <div className='flex justify-center p-6 mb-4'>
      <a
        className='text-center header-cta rounded-xl text-white text-md bg-secondary-base hover:bg-secondary-base py-3 px-3 font-sans tracking-wide font-normal'
        data-formkit-toggle={formkitToggle}
        href={'https://motivated-maker-2860.ck.page/' + formkitToggle}
      >
        {children}
      </a>
    </div>
  );
};

export default ConvertkitButton;
