import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { observer } from "mobx-react-lite";
import UniversalLink from "./UniversalLink";

interface Props {
  title: string,
  uri: string
}
const ArchiveList: React.FC = observer(() => {
  const {
    archive
  } = useStaticQuery(graphql`
      query StaticArchiveListQuery {
          archive: allWpPost(sort: {fields: date, order: DESC}) {
              nodes {
                  title
                  uri
              }
          }
      }
  `);
  return (
    <div className="flex flex-col items-left lg:mt-16 mx-auto m-0">
      <h4 className="mt-8 mb-8 text-xl text-gray-900 font-normal text-left">Archives</h4>
      <ul className="blog-archives">
        { archive && archive.nodes.map(({title, uri}:Props) => (
            <li key={uri}><UniversalLink to={uri}>{title}</UniversalLink></li>
          )
        )}
      </ul>
    </div>
  );
});

export default ArchiveList;

