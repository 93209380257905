import React from 'react';
import { graphql } from 'gatsby';
import DefaultLayout from '../../components/DefaultLayout';
import Seo from 'gatsby-plugin-wpgraphql-seo';
import Img from 'gatsby-image';
import CpaLogo from '../../components/CpaLogo';
import ArchiveList from '../../components/ArchiveList';
import ConvertkitPost from '../../components/ConvertkitPost';

interface Props {}

// @ts-ignore
export default ({ data }) => {
  const { nextPage, previousPage, page } = data;
  const { title, content, featuredImage, categories, excerpt, databaseId, author, date, seo } = page;
  const { canonical } = seo;
  const id = canonical.slice(1).replace(/\/$/, '');
  return (
    <DefaultLayout>
      <Seo post={page} />
      <div className='flex flex-col lg:flex-row'>
        <article
          className={`w-full lg:w-2/3 overflow-hidden container mx-auto mt-0 lg:pl-20 pr-0 mb-16 post-${id} post type-post status-publish format-standard has-post-thumbnail hentry category-uncategorized`}
          id={`post-${id}`}
        >
          <div className='container max-w-4xl mx-auto mt-16 mb-1 px-6 py-4'>
            <header className='entry-header has-text-align-center header-footer-group'>
              <div className='entry-header-inner section-inner medium'>
                {/*<PostCategories categories={categories} />*/}
                <h1
                  className='text-3xl text-left text-primary-base prose mb-4 font-bold'
                  dangerouslySetInnerHTML={{ __html: title }}
                />
                {/*<div*/}
                {/*  className="intro-text section-inner max-percentage small prose"*/}
                {/*  dangerouslySetInnerHTML={{ __html: excerpt }}*/}
                {/*/>*/}
                {/*<PostMeta title={title} author={author} date={date} />*/}
                <p className='mb-8 text-left text-gray-700'>
                  by margento |{' '}
                  <span className='mb-4 text-secondary-base' dangerouslySetInnerHTML={{ __html: date.toString() }} />
                </p>
              </div>
            </header>
            {featuredImage && (
              <Img placeholderClassName={'w-full'} fluid={featuredImage.node.localFile.childImageSharp.fluid} />
            )}

            <div className='entry-content prose max-w-none' dangerouslySetInnerHTML={{ __html: content }} />
            <ConvertkitPost id={id} />
          </div>
          {/*<div className="section-inner">*/}
          {/*<AuthorBio author={author} />*/}
          {/*<ContentTypePagination*/}
          {/*  previousPage={previousPage}*/}
          {/*  nextPage={nextPage}*/}
          {/*  contentType={"Post"}*/}
          {/*/>*/}
          {/*<Comments />*/}
          {/*</div>*/}
        </article>
        <article className='w-full lg:w-1/3 pl-16 pr-24'>
          <CpaLogo />
          <ArchiveList />
        </article>
      </div>
    </DefaultLayout>
  );
};

export const query = graphql`
  query post($id: String!, $nextPage: String, $previousPage: String) {
    page: wpPost(id: { eq: $id }) {
      ...PostContent
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
    }
    nextPage: wpPost(id: { eq: $nextPage }) {
      title
      uri
    }
    previousPage: wpPost(id: { eq: $previousPage }) {
      title
      uri
    }
  }
`;
