import React, { ReactElement } from 'react';
import ConvertkitButton from './ConvertkitButton';

interface Props {
  id: string;
}

const ConvertkitPost: React.FC<Props> = ({ id }) => {
  return (
    <>
      {id === 'understanding-the-shareholder-loan' ? (
        <ConvertkitButton formkitToggle='e1ba28a01a'>Looking for tax tips and tricks?</ConvertkitButton>
      ) : (
        ''
      )}
    </>
  );
};

export default ConvertkitPost;
